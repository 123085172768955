import React, { memo } from 'react';
import useStyles from '../../../../views/EditorItem/styles';
import cx from 'clsx';
import '../../../../assets/main.sass';

interface IEditableInputProps {
  value: string;
  onChange?: (newVal: string) => void;
  className?: string;
  style?: React.CSSProperties;
  onKeyPress?: () => void;
  onClick?: () => void;
  onBlur?: () => void;
}

export const EditableInput = memo((props: IEditableInputProps) => {
  const cc = useStyles();
  return (
    <input
      type="text"
      className={cx(props.className, cc.editableInput)}
      style={props.style}
      value={props.value}
      onChange={(e: any) => props.onChange && props.onChange(e.target.value)}
      onKeyPress={props.onKeyPress}
      onClick={props.onClick}
      onBlur={props.onBlur}
    />
  );
});
