import { useDispatch } from 'react-redux';
import { useTypeSelector } from './useTypesSelector';
import {
  EditorReducerActionEnum,
  IBlock,
  IBlocks,
  InitPage,
  IPages,
  ISiteDraft,
  ISiteDraftPreview,
} from '../store/reducers/editors';
import { DeepCopy } from '../utils/DeepCopy';
import {
  getSiteDraft,
  putSiteDraft,
  removeSiteDraft,
  createSiteDraft,
  getSiteDraftAccess,
  putSiteDraftPreview,
  setDraftInfo,
} from '../api/siteDraft';
import { useCallback } from 'react';
import { useSettingsEditor } from './useSettingEditor';
import { SnackbarType, useSnackbar } from './useSnackbar';
// import { useDo } from './useDo';
import { toPNG } from '../utils/toPNG';
import { toPNGinPreview } from '../utils/toPNGinPreview';
import { DraftReducerActionEnum } from '../store/reducers/draft';
import { useRequest } from './useRequest';

export const base64PngReg = /data:image\/png;base64,/;

const findCountPageChildById = (arr: IPages[], id: string) => {
  let count = -1;
  for (const item of arr) {
    if (item.id === id) {
      count = item.subpages.length;
      return count;
    }
    count = findCountPageChildById(item.subpages, id);
    if (count !== -1) {
      return count;
    }
  }
  return count;
};

const findByIdAddPage = (
  arr: IPages[],
  id: string,
  newPage: IPages,
): IPages[] => {
  return arr.map((item) => {
    if (item.id === id) {
      item.subpages.push(newPage);
      return item;
    }
    item.subpages = findByIdAddPage(item.subpages, id, newPage);
    return item;
  });
};

const findByIdAndRemove = (arr: IPages[], id: string): IPages[] => {
  const newArr = [];
  for (const item of arr) {
    if (item.id !== id) {
      const subpages = findByIdAndRemove(item.subpages, id);
      newArr.push({ ...item, subpages });
    }
  }
  return newArr;
};

const findByIdAddBlock = (
  arr: IPages[],
  id: string,
  newBlock: IBlocks,
): IPages[] => {
  return arr.map((item) => {
    if (item.id === id) {
      const index = item.blocks.length;
      newBlock.index = index;
      item.blocks.push(newBlock);
      return item;
    }
    item.subpages = findByIdAddBlock(item.subpages, id, newBlock);
    return item;
  });
};

const findBlockByIdAndUpdate = (
  arr: IPages[],
  oldBlockId: string,
  newBlock: IBlocks,
): IPages[] => {
  return arr.map((item) => {
    item.blocks = item.blocks.map((block) => {
      if (block.id === oldBlockId) {
        block.title = newBlock.title;
        block.type = newBlock.type;
        block.active = newBlock.active;
        block.comments = newBlock.comments;
      }
      return block;
    });
    item.subpages = findBlockByIdAndUpdate(item.subpages, oldBlockId, newBlock);
    return item;
  });
};

const setBlockCommentById = (
  arr: IPages[],
  oldBlockId: string,
  comment: string,
): IPages[] => {
  return arr.map((item) => {
    for (const block of item.blocks) {
      if (block.id === oldBlockId) {
        if (!block.comments) {
          const comm = [];
          comm.push({ comment });
          block.comments = comm;
        }
        block.comments.push({ comment });

        return item;
      }
    }
    item.subpages = setBlockCommentById(item.subpages, oldBlockId, comment);
    return item;
  });
};

const updateBlockCommentById = (
  arr: IPages[],
  oldBlockId: string,
  comment: string,
  idx: number,
) => {
  return arr.map((item) => {
    //@ts-ignore
    item.blocks = item.blocks.map((block) => {
      if (block.id === oldBlockId) {
        block.comments = block.comments.map((com, index) => {
          if (index === idx) {
            com.comment = comment;
          }
          return com;
        });
      }
      return block;
    });

    item.subpages = updateBlockCommentById(
      item.subpages,
      oldBlockId,
      comment,
      idx,
    );
    return item;
  });
};

const deleteBlockCommentById = (
  arr: IPages[],
  oldBlockId: string,
  idx: number,
): IPages[] => {
  return arr.map((item) => {
    for (const block of item.blocks) {
      if (block.id === oldBlockId) {
        block.comments = block.comments.filter((comm, index) => index !== idx);
        return item;
      }
    }
    item.subpages = deleteBlockCommentById(item.subpages, oldBlockId, idx);
    return item;
  });
};

const findByIdAddBlockAfter = (
  arr: IPages[],
  pageId: string,
  idxAfter: number,
  newBlock: IBlocks,
): IPages[] => {
  return arr.map((item) => {
    if (item.id === pageId) {
      const before = item.blocks.slice(0, idxAfter + 1);
      const after = item.blocks.slice(idxAfter + 1, item.blocks.length);

      const nBlock = Object.assign({}, newBlock);
      const index = before.length + 1 + after.length;

      nBlock.index = index;
      const blocks = [...before, nBlock, ...after];
      item.blocks = blockIndexNormilize(blocks);
      return item;
    }
    item.subpages = findByIdAddBlockAfter(
      item.subpages,
      pageId,
      idxAfter,
      newBlock,
    );
    return item;
  });
};

const findByIdChangeTitle = (arr: IPages[], id: string, newValue: string) => {
  return arr.map((item) => {
    if (item.id === id) {
      item.title = newValue;
      return item;
    }
    item.subpages = findByIdChangeTitle(item.subpages, id, newValue);
    return item;
  });
};

const findBlockTitleById = (arr: IPages[], id: string, newVal: string) => {
  return arr.map((item) => {
    item.blocks = item.blocks.map((block) => {
      if (block.id === id) {
        block.title = newVal;
      }
      return block;
    });
    item.subpages = findBlockTitleById(item.subpages, id, newVal);
    return item;
  });
};

const findPageAndSetComment = (arr: IPages[], id: string, comment: string) => {
  return arr.map((item) => {
    if (item.id === id) {
      if (!item.comments) {
        const comm = [];
        comm.push({ comment });
        item.comments = comm;
      } else {
        item.comments.push({ comment });
      }

      return item;
    }
    item.subpages = findPageAndSetComment(item.subpages, id, comment);
    return item;
  });
};

const findPageAndRemoveComment = (arr: IPages[], id: string, idx: number) => {
  return arr.map((item) => {
    if (item.id === id) {
      item.comments = item.comments.filter((item, index) => index !== idx);

      return item;
    }
    item.subpages = findPageAndRemoveComment(item.subpages, id, idx);
    return item;
  });
};

const findPageAndUpdateComment = (
  arr: IPages[],
  id: string,
  val: string,
  idx: number,
) => {
  return arr.map((item) => {
    if (item.id === id) {
      item.comments = item.comments.map((com, index) => {
        if (index === idx) {
          com.comment = val;
        }
        return com;
      });

      return item;
    }
    item.subpages = findPageAndUpdateComment(item.subpages, id, val, idx);
    return item;
  });
};

const blockIndexNormilize = (blocks: IBlocks[]): IBlocks[] => {
  return DeepCopy(
    blocks.map((block, index) => {
      block.index = index;
      return block;
    }),
  );
};

const findPageAndRemoveBlock = (
  arr: IPages[],
  pageId: string,
  blockId: string,
) => {
  return arr.map((item) => {
    if (item.id === pageId) {
      item.blocks = blockIndexNormilize(
        item.blocks.filter((block) => (block.id === blockId ? false : true)),
      );
      return item;
    }
    item.subpages = findPageAndRemoveBlock(item.subpages, pageId, blockId);
    return item;
  });
};

const setDNDBlock = (
  arr: IPages[],
  dragBlock: IBlock,
  dragOverBlock: IBlock,
): IPages[] => {
  const db: IBlocks = {
    id: dragBlock.id,
    index: dragBlock.index,
    title: dragBlock.title,
    type: dragBlock.type,
    comments: dragBlock.comments,
    active: dragBlock.active,
  };
  const rmArr = findPageAndRemoveBlock(arr, dragBlock.pageId, dragBlock.id);
  return findByIdAddBlockAfter(
    rmArr,
    dragOverBlock.pageId,
    dragOverBlock.index,
    db,
  );
};

export const useEditorSitemap = () => {
  // const Do = useDo();
  const dispatch = useDispatch();
  const { setSnackbarValue } = useSnackbar();
  const { setDraftLoading } = useSettingsEditor();
  const pages = useTypeSelector((s) => s.editor.pages);
  const draftId = useTypeSelector((s) => s.editor.id);
  const draftName = useTypeSelector((s) => s.editor.name);
  const profile = useTypeSelector((s) => s.profile.profile);
  const scale = useTypeSelector((s) => s.settings.dnd.scale);
  const description = useTypeSelector((s) => s.editor.description);
  const { request } = useRequest();

  const putDraftServer = useCallback(
    async (name: string = draftName, p: IPages[] = pages): Promise<boolean> => {
      try {
        setDraftLoading(true);
        const draft: ISiteDraft = {
          id: draftId,
          name,
          pages: p,
          description,
        };
        draftId &&
          (await request(
            async () => await putSiteDraft(draftId, draft),
            () => setSnackbarValue('Не удалось сохранить', SnackbarType.Error),
          ));
        // Do.save(draft);
        return true;
      } finally {
        setDraftLoading(false);
      }
    },
    [
      draftName,
      pages,
      draftId,
      description,
      setDraftLoading,
      setSnackbarValue,
      request,
    ],
  );

  const putPreview = useCallback(async (): Promise<boolean> => {
    // const s = DeepCopy(scale)
    const tempref = document.getElementById('editorContent');
    const ref = tempref?.querySelector('.FirstPage');
    if (!ref) return false;

    try {
      // setScale(1)

      const preview = await toPNGinPreview(
        await toPNG(ref, 'Editor', true, scale, false),
      );

      const draft: ISiteDraftPreview = {
        id: draftId,
        preview: preview.replace(base64PngReg, ''),
      };
      draftId &&
        request(
          async () => await putSiteDraftPreview(draftId, draft),
          () => setSnackbarValue('Не удалось сохранить', SnackbarType.Error),
        );
      return true;
    } finally {
      setDraftLoading(false);
    }
  }, [draftId, setDraftLoading, setSnackbarValue, scale, request]);

  const setEditorSitemap = useCallback(
    (sitemap: IPages[]) => {
      dispatch({
        type: EditorReducerActionEnum.setEditorSitemap,
        payload: DeepCopy<IPages[]>(sitemap),
      });
    },
    [dispatch],
  );

  const countPageChildById = useCallback(
    (id: string) => {
      return findCountPageChildById(pages, id);
    },
    [pages],
  );

  const addPageChildById = useCallback(
    (id: string, newPage: IPages) => {
      // const E = DeepCopy<IPages[]>(pages);
      const p = findByIdAddPage(pages, id, newPage);
      setEditorSitemap(p);
      putDraftServer(draftName, p);
    },
    [pages, draftName, setEditorSitemap, putDraftServer],
  );

  const removePageById = useCallback(
    (id: string) => {
      // const E = DeepCopy<IPages[]>(pages);
      const p = findByIdAndRemove(pages, id);
      if (!p.length) {
        p.push(InitPage);
      }
      setEditorSitemap(p);
      putDraftServer(draftName, p);
    },
    [pages, draftName, setEditorSitemap, putDraftServer],
  );

  const addBlockToPageById = useCallback(
    (block: IBlocks, id: string) => {
      // const E = DeepCopy<IPages[]>(pages);
      const p = findByIdAddBlock(pages, id, block);
      setEditorSitemap(p);
      putDraftServer(draftName, p);
    },
    [pages, draftName, putDraftServer, setEditorSitemap],
  );

  const findBlockAndUpdate = useCallback(
    (block: IBlocks, oldId: string) => {
      // const E = DeepCopy<IPages[]>(pages);
      const p = findBlockByIdAndUpdate(pages, oldId, block);
      setEditorSitemap(p);
      putDraftServer(draftName, p);
    },
    [pages, draftName, putDraftServer, setEditorSitemap],
  );

  const changeTitleFromPageById = useCallback(
    (id: string, newVal: string) => {
      // const E = DeepCopy<IPages[]>(pages);
      const p = findByIdChangeTitle(pages, id, newVal);
      setEditorSitemap(p);
      putDraftServer(draftName, p);
    },
    [pages, draftName, setEditorSitemap, putDraftServer],
  );

  const changeTitleFromBlockById = useCallback(
    (id: string, newVal: string) => {
      // const E = DeepCopy<IPages[]>(pages);
      const p = findBlockTitleById(pages, id, newVal);
      setEditorSitemap(p);
      putDraftServer(draftName, p);
      // setEditorSitemap(p);
    },
    [pages, draftName, setEditorSitemap, putDraftServer],
  );

  const addBlockAfterIdxToPageById = useCallback(
    (id: string, idx: number, newBlock: IBlocks) => {
      // const E = DeepCopy<IPages[]>(pages);
      const p = findByIdAddBlockAfter(pages, id, idx, newBlock);
      setEditorSitemap(p);
      putDraftServer(draftName, p);
    },
    [pages, draftName, setEditorSitemap, putDraftServer],
  );

  const setPageComment = useCallback(
    (pageId: string, commentText: string) => {
      const p = findPageAndSetComment(pages, pageId, commentText);
      setEditorSitemap(p);
    },
    [
      pages,
      // draftName,
      // putDraftServer,
      setEditorSitemap,
    ],
  );

  const deletePageComment = useCallback(
    (pageId: string, idx: number) => {
      const p = findPageAndRemoveComment(pages, pageId, idx);
      setEditorSitemap(p);
    },
    [pages, setEditorSitemap],
  );

  const updatePageComment = useCallback(
    (pageId: string, val: string, idx: number) => {
      const p = findPageAndUpdateComment(pages, pageId, val, idx);
      setEditorSitemap(p);
    },
    [pages, setEditorSitemap],
  );

  const setBlockComment = useCallback(
    (blockId: string, comment: string) => {
      const p = setBlockCommentById(pages, blockId, comment);
      setEditorSitemap(p);
    },
    [pages, setEditorSitemap],
  );

  const updBlockComment = useCallback(
    (blockId: string, comment: string, idx: number) => {
      const p = updateBlockCommentById(pages, blockId, comment, idx);
      setEditorSitemap(p);
    },
    [pages, setEditorSitemap],
  );

  const deleteBlockComment = useCallback(
    (blockId: string, idx: number) => {
      const p = deleteBlockCommentById(pages, blockId, idx);
      setEditorSitemap(p);
    },
    [pages, setEditorSitemap],
  );

  const removeBlockByPageIdAndIdx = useCallback(
    (pageId: string, block: IBlocks) => {
      // const E = DeepCopy<IPages[]>(pages);
      const p = findPageAndRemoveBlock(pages, pageId, block.id);
      setEditorSitemap(p);
      putDraftServer(draftName, p);
    },
    [pages, draftName, putDraftServer, setEditorSitemap],
  );

  const setDraftName = useCallback(
    async (name: string) => {
      dispatch({
        type: EditorReducerActionEnum.setDraftName,
        payload: name,
      });
      await putDraftServer(draftName, pages);
    },
    [dispatch, draftName, pages, putDraftServer],
  );

  const removeDraft = async (id: string) => {
    await removeSiteDraft(id);
  };

  // const genPage = (pages: Page[]): Page[] => {
  //   const p = [];
  //   for (const page of pages) {
  //     console.log('page', page);
  //     const np = new Page(page);
  //     np.setPageSubpages &&
  //       np.setPageSubpages([...(genPage(page.subpages || []) || [])]);
  //     p.push(np);
  //   }
  //   return p;
  // };

  const setEvents = useCallback(
    async (id: string, type: 'OPEN' | 'CLOSE') => {
      // send info about open or close draft
      await request(async () => await setDraftInfo(id, type));
    },
    [request],
  );

  const getDraft = useCallback(
    async (id: string) => {
      const data = await request(async () => await getSiteDraft(id));
      // console.log('data.pages', data.pages);
      // const pages = genPage(data.pages);
      // console.log('pages', pages);
      if (data) {
        dispatch({
          type: EditorReducerActionEnum.setDraftName,
          payload: data?.name,
        });
        dispatch({
          type: EditorReducerActionEnum.setDescription,
          payload: data?.description,
        });
        dispatch({
          type: EditorReducerActionEnum.setEditorSitemap,
          payload: data?.pages,
        });
        dispatch({
          type: EditorReducerActionEnum.setEditorSitemapPageCount,
          payload: data?.pages_count,
        });

        dispatch({
          type: EditorReducerActionEnum.setOwn_draft,
          payload: data.own_draft,
        });
      }
    },
    [dispatch, request],
  );

  const getDraftAccess = useCallback(
    async (id: string) => {
      if (profile) {
        try {
          const access = await getSiteDraftAccess(id);
          dispatch({
            type: DraftReducerActionEnum.setDraftAccess,
            payload: access.data,
          });
        } catch (e) {
          console.error(e);
        }
      }
    },
    [dispatch, profile],
  );

  const copyDraft = async (id: string) => {
    console.log('copyDraft');
    try {
      const draft = (await getSiteDraft(id)).data;
      const SiteDraft = await createSiteDraft();
      if (SiteDraft.ok === false) {
        setSnackbarValue(
          SiteDraft.error.response.data.error_description,
          SnackbarType.Error,
        );
        return;
      }
      if (draft && SiteDraft.data && SiteDraft.data.id) {
        draft.name = `${draft.name} дубликат`;
        await putSiteDraft(SiteDraft.data.id, draft);
      }
      setSnackbarValue('Добавили', SnackbarType.Success);
    } catch (e: any) {
      setSnackbarValue(e.error.error_description, SnackbarType.Error);
    }
  };

  const undoSitemap = useCallback(
    async (name: string, sitemap: IPages[]) => {
      setEditorSitemap(sitemap);
      setDraftName(name);
      // await putDraftServer();
    },
    [setDraftName, setEditorSitemap],
  );

  const dndBlock = useCallback(
    (dragBlock: IBlock, dragOverBlock: IBlock) => {
      // const E = DeepCopy<IPages[]>(pages);
      const p = setDNDBlock(pages, dragBlock, dragOverBlock);
      setEditorSitemap(p);
      putDraftServer(draftName, p);
    },
    [draftName, pages, setEditorSitemap, putDraftServer],
  );

  const putProjectDescription = useCallback(
    (payload: string) => {
      dispatch({
        type: EditorReducerActionEnum.setDescription,
        payload,
      });
    },
    [dispatch],
  );

  return {
    setEditorSitemap,
    addPageChildById,
    addBlockToPageById,
    findBlockAndUpdate,
    changeTitleFromPageById,
    changeTitleFromBlockById,
    addBlockAfterIdxToPageById,
    setPageComment,
    deletePageComment,
    updatePageComment,
    setBlockComment,
    updBlockComment,
    deleteBlockComment,
    getDraft,
    setEvents,
    getDraftAccess,
    removeDraft,
    setDraftName,
    putDraftServer,
    removeBlockByPageIdAndIdx,
    copyDraft,
    removePageById,
    undoSitemap,
    dndBlock,
    putPreview,
    countPageChildById,
    putProjectDescription,
    clearDraft: () => {
      dispatch({
        type: EditorReducerActionEnum.clearDraft,
      });
    },
  };
};
