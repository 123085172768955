import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { setPaymentsAPI } from '../../../api/payments.api';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { TariffTypeSelector } from '../../../components/TariffTypeSelector/TariffTypeSelector';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { useProfile } from '../../../hooks/useProfile';
import Button from '../../../components/Button/Button';
import { useProfileStyles } from '../styles/profileStyles';
import cx from 'clsx';

export interface ITariffs {
  price: number;
  descPrice?: number;
  descount: number;
  id: string;
  name: string;
  desc: ReactNode;
  list: any;
  sale: boolean;
}

export const SelectTariffs: FC = () => {
  const cc = useProfileStyles();
  const mobileStartAt = 888;
  const serverTariffs = useTypeSelector((s) => s.profile.tariffs);
  const recurring = useTypeSelector((s) => s.profile.recurring);
  const subscription = useTypeSelector(
    (s) => s.profile.profile?.account.subscription,
  );
  const [width, setWidth] = useState(0);
  // const [height, setHeight] = useState(0);

  const [plan, setPlan] = useState(true);
  const [promo, setPromo] = useState('');
  const { CheckPromo, getTarrifs, setAutoLong } = useProfile();

  const sendPromoToCheck = useCallback(async () => {
    await CheckPromo(promo);
    setPromo('');
    await getTarrifs();
  }, [promo, CheckPromo, getTarrifs]);

  const selectPlan = useCallback(
    async (id: string) => {
      const payload = {
        purchase_id: id,
        recurring,
      };
      try {
        const { data } = await setPaymentsAPI(payload);
        const link = data?.payment_link;
        if (link) {
          const a = document.createElement('a');
          a.href = link;
          a.target = '_blank';
          a.click();
        }
      } catch (e) {
        console.error(e);
      }
    },
    [recurring],
  );

  const setPlantAndTariffs = useCallback((val: boolean) => {
    setPlan(val);
  }, []);

  const getWidth = (e: any) => {
    if (e.type === 'load') {
      setWidth(window.innerWidth);
      // setHeight(window.innerHeight);
      return;
    }
    setWidth(e.target.innerWidth);
    // setHeight(e.target.innerHeight);
  };

  useEffect(() => {
    serverTariffs ? setPlantAndTariffs(plan) : setPlantAndTariffs(plan);
  }, [serverTariffs, setPlantAndTariffs, plan]);

  useEffect(() => {
    getTarrifs();
    window.addEventListener('resize', getWidth);

    return () => {
      window.removeEventListener('resize', getWidth);
    };
  }, [getTarrifs]);

  return (
    <div className={cc.select_tariffs_wrapper} onLoad={getWidth}>
      <TariffTypeSelector
        value={plan}
        onChange={setPlantAndTariffs}
        mobile={width < mobileStartAt}
      />

      <div className={cc.promo}>
        <TextField
          type="text"
          value={promo}
          onChange={(e) => setPromo(e.target.value)}
          placeholder="Промокод"
          label="Промокод"
          variant="outlined"
          style={{ width: '100%', position: 'relative' }}
        />
        <Button
          className={cx(
            cc.orangeBtnOutline,
            !promo.length ? cc.disabledOrangeBtnOutline : '',
          )}
          onClick={sendPromoToCheck}
        >
          Применить
        </Button>
      </div>

      {serverTariffs?.length ? (
        <Button
          className={cc.orangeBtn}
          onClick={() => selectPlan(serverTariffs[1].options[plan ? 0 : 1].id)}
        >
          {subscription?.tariff_type === 'TRIAL' ? (
            <>
              Оплатить {serverTariffs[1].options[plan ? 0 : 1].cost}₽ за{' '}
              {String(
                serverTariffs[1].options[plan ? 0 : 1].name,
              ).toLocaleLowerCase()}
            </>
          ) : (
            <>
              Продлить на{' '}
              {String(
                serverTariffs[1].options[plan ? 0 : 1].name,
              ).toLocaleLowerCase()}{' '}
              за {serverTariffs[1].options[plan ? 0 : 1].cost}₽
            </>
          )}
        </Button>
      ) : (
        <></>
      )}

      <FormControlLabel
        className={cx(cc.promoCheck, cc.orangeCheckbox)}
        control={
          <Checkbox
            value={recurring}
            onChange={(evt, val) => setAutoLong(val)}
            // color="primary"
            checkedIcon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* <mask
                  id="mask0_8581_6889"
                  style="mask-type:alpha"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask> */}
                <g mask="url(#mask0_8581_6889)">
                  <path
                    d="M10.6 15.8538L17.323 9.13075L16.2692 8.07693L10.6 13.7462L7.74998 10.8962L6.69615 11.95L10.6 15.8538ZM5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.3077C3.5 4.80257 3.675 4.375 4.025 4.025C4.375 3.675 4.80257 3.5 5.3077 3.5H18.6923C19.1974 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.80257 20.5 5.3077V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.3077ZM5.3077 19H18.6923C18.7692 19 18.8397 18.9679 18.9038 18.9038C18.9679 18.8397 19 18.7692 19 18.6923V5.3077C19 5.23077 18.9679 5.16024 18.9038 5.09613C18.8397 5.03203 18.7692 4.99998 18.6923 4.99998H5.3077C5.23077 4.99998 5.16024 5.03203 5.09612 5.09613C5.03202 5.16024 4.99997 5.23077 4.99997 5.3077V18.6923C4.99997 18.7692 5.03202 18.8397 5.09612 18.9038C5.16024 18.9679 5.23077 19 5.3077 19Z"
                    fill="#F2994A"
                  />
                </g>
              </svg>
            }
          />
        }
        label="Автоматическое продление подписки"
      />

      <section>
        <hr className={cc.hr} />
        <label className={cc.label}>Преимущества</label>
        <ul className={cc.listActives}>
          <li>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.49997 17.25C4.03634 17.25 2.79543 16.741 1.77725 15.723C0.759084 14.7049 0.25 13.4642 0.25 12.0009C0.25 10.5375 0.759084 9.29646 1.77725 8.27788C2.79543 7.25929 4.03634 6.75 5.49997 6.75C6.08276 6.75 6.64192 6.85353 7.17745 7.06058C7.71297 7.26763 8.19611 7.56153 8.62687 7.9423L10.5385 9.62693L9.40385 10.6308L7.62308 9.05765C7.33076 8.80637 7.00191 8.60893 6.63653 8.46535C6.27114 8.32175 5.89206 8.24995 5.49927 8.24995C4.46374 8.24995 3.57998 8.61618 2.84798 9.34863C2.11597 10.0811 1.74998 10.9654 1.74998 12.0015C1.74998 13.0377 2.11597 13.9215 2.84798 14.6529C3.57998 15.3843 4.46374 15.75 5.49927 15.75C5.89206 15.75 6.27114 15.6782 6.63653 15.5346C7.00191 15.391 7.33076 15.1936 7.62308 14.9423L15.3731 7.9423C15.7974 7.55512 16.2784 7.2596 16.816 7.05575C17.3536 6.85192 17.9149 6.75 18.5 6.75C19.9636 6.75 21.2045 7.259 22.2227 8.277C23.2409 9.29502 23.75 10.5357 23.75 11.9991C23.75 13.4625 23.2409 14.7035 22.2227 15.7221C21.2045 16.7407 19.9636 17.25 18.5 17.25C17.9154 17.25 17.3574 17.1448 16.826 16.9346C16.2945 16.7243 15.8102 16.432 15.3731 16.0577L13.4808 14.373L14.5961 13.3596L16.3769 14.9423C16.6692 15.2013 16.998 15.4006 17.3634 15.5404C17.7288 15.6801 18.1079 15.75 18.5007 15.75C19.5362 15.75 20.42 15.3838 21.152 14.6513C21.884 13.9189 22.25 13.0346 22.25 11.9984C22.25 10.9623 21.884 10.0785 21.152 9.34707C20.42 8.61566 19.5362 8.24995 18.5007 8.24995C18.1079 8.24995 17.7288 8.32175 17.3634 8.46535C16.998 8.60893 16.6692 8.80637 16.3769 9.05765L8.62687 16.0577C8.20253 16.4448 7.72155 16.7404 7.18395 16.9442C6.64633 17.148 6.08501 17.25 5.49997 17.25Z"
                fill="#F2994A"
              />
            </svg>
            <span>Безлимитное количество проектов, страниц и блоков</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M8.84945 16.8261L11.9995 14.9261L15.1495 16.8511L14.3245 13.2511L17.0995 10.8511L13.4495 10.5261L11.9995 7.12608L10.5495 10.5011L6.89945 10.8261L9.67445 13.2511L8.84945 16.8261ZM6.5745 19.9625L8.00718 13.7934L3.2207 9.64533L9.53603 9.09728L11.9995 3.28003L14.4629 9.09728L20.7782 9.64533L15.9917 13.7934L17.4244 19.9625L11.9995 16.6895L6.5745 19.9625Z"
                fill="#F2994A"
              />
            </svg>
            <span>Приоритетный доступ к краулеру</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6.38463 14.8461H7.5769V9.15383H5.1923V10.3461H6.38463V14.8461ZM9.6923 14.8461H12.1154C12.3667 14.8461 12.5769 14.7615 12.7461 14.5923C12.9154 14.423 13 14.2128 13 13.9615V10.0385C13 9.78717 12.9154 9.57691 12.7461 9.40768C12.5769 9.23844 12.3667 9.15383 12.1154 9.15383H9.6923C9.44102 9.15383 9.23076 9.23844 9.06153 9.40768C8.89231 9.57691 8.8077 9.78717 8.8077 10.0385V13.9615C8.8077 14.2128 8.89231 14.423 9.06153 14.5923C9.23076 14.7615 9.44102 14.8461 9.6923 14.8461ZM9.99998 13.6538V10.3461H11.8077V13.6538H9.99998ZM14.1846 14.8461H15.3769V12.5384L17.3961 14.8461H18.9231L16.4346 11.9808L18.9231 9.15383H17.4154L15.3769 11.4423V9.15383H14.1846V14.8461ZM5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.3077C3.5 4.80257 3.675 4.375 4.025 4.025C4.375 3.675 4.80257 3.5 5.3077 3.5H18.6923C19.1974 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.80257 20.5 5.3077V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.3077ZM5.3077 19H18.6923C18.7692 19 18.8397 18.9679 18.9038 18.9038C18.9679 18.8397 19 18.7692 19 18.6923V5.3077C19 5.23077 18.9679 5.16024 18.9038 5.09613C18.8397 5.03203 18.7692 4.99998 18.6923 4.99998H5.3077C5.23077 4.99998 5.16024 5.03203 5.09612 5.09613C5.03202 5.16024 4.99997 5.23077 4.99997 5.3077V18.6923C4.99997 18.7692 5.03202 18.8397 5.09612 18.9038C5.16024 18.9679 5.23077 19 5.3077 19Z"
                fill="#F2994A"
              />
            </svg>
            <span>До 10 000 страниц в краулере</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12 15.7884L7.7308 11.5193L8.78462 10.4347L11.25 12.9V4.5H12.7499V12.9L15.2153 10.4347L16.2692 11.5193L12 15.7884ZM6.3077 19.5C5.80257 19.5 5.375 19.325 5.025 18.975C4.675 18.625 4.5 18.1974 4.5 17.6923V14.9808H5.99997V17.6923C5.99997 17.7692 6.03202 17.8397 6.09612 17.9038C6.16024 17.9679 6.23077 18 6.3077 18H17.6922C17.7692 18 17.8397 17.9679 17.9038 17.9038C17.9679 17.8397 18 17.7692 18 17.6923V14.9808H19.5V17.6923C19.5 18.1974 19.325 18.625 18.975 18.975C18.625 19.325 18.1974 19.5 17.6922 19.5H6.3077Z"
                fill="#F2994A"
              />
            </svg>
            <span>Экспорт PNG\PDF\DOCX</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11.9804 20.5C9.81501 20.5 7.92783 19.7871 6.31886 18.3615C4.7099 16.9359 3.78362 15.1487 3.54004 13H5.07076C5.32333 14.7269 6.10057 16.1586 7.40249 17.2952C8.70442 18.4317 10.2304 19 11.9804 19C13.9304 19 15.5846 18.3208 16.9429 16.9625C18.3012 15.6041 18.9804 13.95 18.9804 12C18.9804 10.05 18.3012 8.39581 16.9429 7.03748C15.5846 5.67914 13.9304 4.99998 11.9804 4.99998C10.8881 4.99998 9.86437 5.2426 8.90924 5.72785C7.95412 6.2131 7.13169 6.88073 6.44194 7.73073H9.05731V9.2307H3.98046V4.15383H5.48041V6.52308C6.29195 5.56539 7.26374 4.82213 8.39579 4.29328C9.52784 3.76443 10.7227 3.5 11.9804 3.5C13.1599 3.5 14.265 3.72308 15.2958 4.16925C16.3265 4.6154 17.2252 5.2218 17.9919 5.98845C18.7586 6.75512 19.365 7.65383 19.8111 8.6846C20.2573 9.71537 20.4803 10.8205 20.4803 12C20.4803 13.1795 20.2573 14.2846 19.8111 15.3154C19.365 16.3461 18.7586 17.2448 17.9919 18.0115C17.2252 18.7782 16.3265 19.3846 15.2958 19.8307C14.265 20.2769 13.1599 20.5 11.9804 20.5ZM14.9823 16.0269L11.2592 12.3038V6.99998H12.7592V11.6961L16.0361 14.9731L14.9823 16.0269Z"
                fill="#F2994A"
              />
            </svg>
            <span>История сохраняется вечно</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M11 17H7C5.61667 17 4.43767 16.5123 3.463 15.537C2.48767 14.5623 2 13.3833 2 12C2 10.6167 2.48767 9.43733 3.463 8.462C4.43767 7.48733 5.61667 7 7 7H11V9H7C6.16667 9 5.45833 9.29167 4.875 9.875C4.29167 10.4583 4 11.1667 4 12C4 12.8333 4.29167 13.5417 4.875 14.125C5.45833 14.7083 6.16667 15 7 15H11V17ZM8 13V11H16V13H8ZM13 17V15H17C17.8333 15 18.5417 14.7083 19.125 14.125C19.7083 13.5417 20 12.8333 20 12C20 11.1667 19.7083 10.4583 19.125 9.875C18.5417 9.29167 17.8333 9 17 9H13V7H17C18.3833 7 19.5627 7.48733 20.538 8.462C21.5127 9.43733 22 10.6167 22 12C22 13.3833 21.5127 14.5623 20.538 15.537C19.5627 16.5123 18.3833 17 17 17H13Z"
                fill="#F2994A"
              />
            </svg>
            <span>Доступ к проекту по ссылке</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18.4 2C18.1 2 17.9 2.1 17.7 2.3L15.7 4.3L14.3 5.7L3 17V21H7L21.7 6.3C22.1 5.9 22.1 5.3 21.7 4.9L19.1 2.3C18.9 2.1 18.7 2 18.4 2ZM18.4 4.4L19.6 5.6L18.3 6.9L17.1 5.7L18.4 4.4ZM15.7 7.1L16.9 8.3L6.2 19H5V17.8L15.7 7.1Z"
                fill="#F2994A"
              />
            </svg>
            <span>Совместное редактирование (β)</span>
          </li>
        </ul>
      </section>
    </div>
  );
};
