import { v4 as uuid } from 'uuid';
import { ReactNode } from 'react';
import { sourceTypes } from '../../api/siteDraft';

export type BlockTypes =
  | 'PICTURE_AND_TEXT'
  | 'TEXT_AND_PICTURE'
  | 'TEXT'
  | '2_COLUMNS_TEXT'
  | '3_COLUMNS_TEXT'
  | '5_COLUMNS_TEXT'
  | 'PICTURE'
  | 'HEADER'
  | 'MIXED'
  | 'SEARCH'
  | 'SEARCH_RESULT'
  | 'CARUSEL'
  | 'CARUSEL_TEXT'
  | 'TEAMS'
  | 'DOWNLOAD_FILE'
  | 'VIDEO'
  | 'AUTH'
  | 'REGISTRATION'
  | 'SOCIAL'
  | 'BACK_FORM'
  | 'PICTURE_AND_ACTION'
  | 'TEXT_AND_ACTION'
  | 'CARD_ITEM'
  | 'MAP'
  | 'PARTNERS'
  | 'CALL_ACTION'
  | 'TIMELINE'
  | 'TABLES'
  | 'TABLES_2'
  | 'CONTACTS'
  | 'POPUP_WIDGETS'
  | '2_COLUMNS_PICTURE'
  | '3_COLUMNS_PICTURE'
  | '3_COLUMNS_CARDS'
  | 'FOOTER'
  | '3_TO_2_ELEMENTS'
  | 'FORM_WITH_VIDEO'
  | 'FORM_WITH_TEXT'
  | 'FORM_WITH_PICTURE'
  | 'FAQ'
  | 'TEXT_WITH_VIDEO'
  | 'VIDEO_WITH_TEXT'
  | 'BLOGS'
  | 'GOODS_AND_SERVICE'
  | 'LIST_BLOCK'
  | 'QUOTE_AND_REVIEW'
  | 'FILTERS'
  | 'QUIZ_OR_SERVEY'
  | 'COMMENTS'
  | 'EMPTY'
  | 'UNKNOWN'
  | 'CUSTOM';

export interface IBlocks {
  id: string;
  index: number;
  title: string;
  // color?: ColorsEnum;
  type: BlockTypes;
  comments: IComment[];
  active: boolean;
}
export interface IBlock extends IBlocks {
  // addAfterBlock: (id: string, idx: number) => void;
  clickOnBlock: (id: string, block: IBlocks) => void;
  // removeBlock: (id: string, block: IBlocks) => void;
  titleEditAllowed?: boolean;
  showEditableBlock: boolean;
  setShowEditableBlock: (val: boolean) => void;
  editBlockId: string;
  setShowEditPageId: (id: string) => void;
  showAddBtn?: boolean;
  ref?: any;
  pageId: string;
  children?: ReactNode;
  className?: string;
  pub?: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  mouse?: boolean;
  // titleEditor?: ReactNode
  // addBlockBtn?: ReactNode
  // removeBlockBtn?: ReactNode
}

export interface IComment {
  comment: string;
}
export interface IPages {
  id: string;
  title: string;
  index: number;
  types: 'Page';
  comments: IComment[];
  blocks: IBlocks[];
  subpages: IPages[];
}

export interface IPage {
  editor: IPages[];
  onTitleChange: (id: string) => void;
  addBlock: (id: string) => void;
  addPage: (id: string) => void;
  addAfterBlock: (id: string) => void;
}

export interface ISiteDraft {
  id: string;
  name: string;
  pages: IPages[];
  preview?: string;
  own_draft?: boolean;
  source?: sourceTypes;
  blocks_count?: number;
  comments_count?: number;
  pages_count?: number;
  description: string;
}
export interface ISiteDraftPreview {
  id: string;
  preview: string;
}

export const InitPage: IPages = {
  id: uuid(),
  title: 'Страница',
  types: 'Page',
  index: 0,
  blocks: [],
  subpages: [],
  comments: [],
};

export enum EditorReducerActionEnum {
  setEditorSitemap = 'SET_EDITOR_SITEMAP',
  setDraftName = 'SET_DRAFT_NAME',
  setDraftId = 'SET_DRAFT_ID',
  clearDraft = 'CLEAR_DRAFT',
  setOwn_draft = 'SET_OWN_DRAFT',
  setDescription = 'SET_DESCRIPTION',
  setEditorSitemapPageCount = 'SET_EDITOR_SITEMAP_PAGE_COUNT',
}

interface EditorReducerActionSetEditorSitemap {
  type: EditorReducerActionEnum.setEditorSitemap;
  payload: IPages[];
}
interface EditorReducerActionSetDraftName {
  type: EditorReducerActionEnum.setDraftName;
  payload: string;
}
interface EditorReducerActionSetDraftId {
  type: EditorReducerActionEnum.setDraftId;
  payload: string;
}
interface EditorReducerActionClearDraft {
  type: EditorReducerActionEnum.clearDraft;
}
interface EditorReducerActionSetOwn_draft {
  type: EditorReducerActionEnum.setOwn_draft;
  payload: boolean;
}
interface EditorReducerActionSetDescription {
  type: EditorReducerActionEnum.setDescription;
  payload: string;
}

interface EditorReducerActionSetEditorSitemapPageCount {
  type: EditorReducerActionEnum.setEditorSitemapPageCount;
  payload: number;
}

const IS: ISiteDraft = {
  id: '',
  name: '',
  preview: '',
  own_draft: false,
  blocks_count: 0,
  comments_count: 0,
  pages_count: 0,
  description: '',
  pages: [
    {
      id: uuid(),
      title: 'Главная страница',
      types: 'Page',
      index: 0,
      blocks: [],
      subpages: [],
      comments: [],
    },
  ],
  source: 'MANUAL',
};
type EditorReducerAction =
  | EditorReducerActionSetEditorSitemap
  | EditorReducerActionSetDraftName
  | EditorReducerActionClearDraft
  | EditorReducerActionSetDraftId
  | EditorReducerActionSetOwn_draft
  | EditorReducerActionSetDescription
  | EditorReducerActionSetEditorSitemapPageCount;

export const EditorReducer = (
  state = IS,
  action: EditorReducerAction,
): ISiteDraft => {
  switch (action.type) {
    case EditorReducerActionEnum.setEditorSitemap:
      return { ...state, pages: action.payload };
    case EditorReducerActionEnum.setDraftName:
      return { ...state, name: action.payload };
    case EditorReducerActionEnum.setDraftId:
      return { ...state, id: action.payload };
    case EditorReducerActionEnum.clearDraft:
      return { ...state, pages: [], name: '', id: '', description: '' };
    case EditorReducerActionEnum.setOwn_draft:
      return { ...state, own_draft: action.payload };
    case EditorReducerActionEnum.setDescription:
      return { ...state, description: action.payload };
    case EditorReducerActionEnum.setEditorSitemapPageCount:
      return { ...state, pages_count: action.payload };
    default:
      return state;
  }
};
