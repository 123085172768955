import React, { useCallback, useEffect } from 'react';
import { getPaymentsAPI } from '../../../api/payments.api';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { useDispatch } from 'react-redux';
import { PaymentsReducerActionEnum } from '../../../store/reducers/settings/payments';
import moment from 'moment';
import { Card, CardContent, Grid } from '@material-ui/core';
import { useProfileStyles } from '../styles/profileStyles';
import cx from 'clsx';

export const PaymentsList = () => {
  const classes = useProfileStyles();
  const Label = ['Дата', 'Сумма ₽'];
  const List = useTypeSelector((s) => s.settings.payments.list);
  const dispatch = useDispatch();

  const getPayment = useCallback(async () => {
    const res = await getPaymentsAPI();
    dispatch({
      type: PaymentsReducerActionEnum.setPayments,
      payload: res.data,
    });
  }, [dispatch]);

  useEffect(() => {
    !List && getPayment();
  }, [List, getPayment]);

  return (
    <Card className={cx(classes.card, 'card')}>
      <div className={classes.cardHeader}>
        История транзакций
        <hr className={classes.hr} />
      </div>
      <CardContent>
        <Grid style={{ display: 'grid', rowGap: 24 }}>
          <div className={classes.paymentListTable}>
            <div className={classes.paymentListTableRowHeader}>
              {Label.map((item, idx) => (
                <span key={idx}>{item}</span>
              ))}
            </div>
            {List?.map((row) => (
              <div className={classes.paymentListTableRow} key={row.id}>
                <span>{moment(row.confirm_ts).format('DD.MM.YYYY')}</span>
                <span>{row.total}</span>
              </div>
            ))}
          </div>
          {/* <Button onClick={onDownloadFile} className={classes.blueBtn}>
            Скачать выписку
          </Button> */}
        </Grid>
      </CardContent>
    </Card>
  );
};
