import { Card, CardContent, Typography } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import useStyles from './styles';
import { useTypeSelector } from '../../hooks/useTypesSelector';
import { Steper } from './Steper';
import { Finish } from './Person/Finish';
import { useRegistration } from '../../hooks/useRegistration';
import { SnackbarType, useSnackbar } from '../../hooks/useSnackbar';
import { useLocation } from 'wouter';

interface ISearch {
  step: string;
}

export const RegistrationSteps = () => {
  const classes = useStyles();
  const title = useTypeSelector((s) => s.registration.title);
  const step = useTypeSelector((s) => s.registration.step);
  const [location, setLocation] = useLocation();
  const { setStep, setTitle, setDescription, checkTokenExperation } =
    useRegistration();
  const setSnackbarValue = useSnackbar((s) => s.setSnackbarValue);

  const search: ISearch | undefined = (() => {
    return window.location.search
      ? window.location.search
          .replace('?', '')
          .split('&')
          .map((item) => {
            const obj = item.split('=');
            const nObj: ISearch = {
              step: '-1',
            };
            // @ts-ignore
            nObj[obj[0]] = obj[1];
            return nObj;
          })[0]
      : undefined;
  })();

  const checkToken = useCallback(
    async (token: string) => {
      try {
        await checkTokenExperation(token);
      } catch (e: any) {
        if (e.error.error === 'invalid_token') {
          setSnackbarValue('Ваша ссылка уже устарела', SnackbarType.Error);
          setTimeout(() => {
            setStep(0);
            setTitle('Регистрация');
            setDescription('ВВЕДИТЕ ДАННЫЕ РЕГИСТРАЦИИ');
            setLocation('/registration', { replace: true });
          }, 2000);
        }
      }
    },
    [
      checkTokenExperation,
      setSnackbarValue,
      setLocation,
      setStep,
      setDescription,
      setTitle,
    ],
  );

  useEffect(() => {
    const id = location.replace('/registration/', '');
    const reg_type = localStorage.getItem('reg_type');
    if (reg_type === 'normal') {
      checkToken(id);
    }
  }, [location,checkToken]);

  useEffect(() => {
    if (
      search !== undefined &&
      search.step !== undefined &&
      search.step !== '-1'
    ) {
      setSnackbarValue('Регистрация прошла успешно', SnackbarType.Success);
      setTitle('Ура!');
      setDescription('данные отправлены и регистрация завершена!');
      setStep(parseInt(search.step));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h1" className={classes.title}>
            {title}
          </Typography>
          {step === 5 && <Finish />}
          {step < 5 && <Steper />}
        </CardContent>
      </Card>
    </div>
  );
};
