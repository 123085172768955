import React, { memo, useCallback, useState } from 'react';
import {
  ExportReportFileTypes,
  getSiteDraftPDF,
  IGenerateReportData,
} from '../../../api/siteDraft';
import { useDND } from '../../../hooks/useDND';
import { base64PngReg } from '../../../hooks/useEditorSitemap';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { toPNG } from '../../../utils/toPNG';
import { toPNGinPreview } from '../../../utils/toPNGinPreview';
import moment from 'moment';
import { useSettingsEditor } from '../../../hooks/useSettingEditor';
import { makeStyles } from '@material-ui/core';
import { IconsOutline } from '../../IconOutliner';
import { SnackbarType, useSnackbar } from '../../../hooks/useSnackbar';

export interface IExportToReportProps {
  format: ExportReportFileTypes;
  loading: boolean;
  className?: string;
}

const useStyle = makeStyles(() => ({
  PDF: {
    background: '#FDEDED',
    '&:hover': {
      border: '1px solid #EA4848',
    },
  },
  DOC: {
    background: '#F6FBFF',
    '&:hover': {
      border: '1px solid #3090E8',
    },
  },
  DOCX: {
    background: '#F6FBFF',
    '&:hover': {
      border: '1px solid #3090E8',
    },
  },
}));

export const ExportToReport = memo(
  ({ format, loading, className }: IExportToReportProps) => {
    const cc = useStyle();
    const scale = useTypeSelector((s) => s.settings.dnd.scale);
    const editorId = useTypeSelector((s) => s.editor.id);
    const editorName = useTypeSelector((s) => s.editor.name);
    const profile = useTypeSelector((s) => s.profile.profile);
    const { setScale } = useDND();
    const { exportReportGeneration } = useSettingsEditor();
    const [load, setLoad] = useState(false);
    const { setSnackbarValue } = useSnackbar();

    const ExportToFile = useCallback(async () => {
      if (loading) return;
      if (!profile) {
        setSnackbarValue(
          'Вы не авторизованы! Нет права на экспорт файлов',
          SnackbarType.Error,
        );
        return;
      }

      setLoad(true);
      exportReportGeneration(true);
      const s = scale;
      setScale(1);
      const tempref = document.getElementById('editorContent');
      const ref = tempref?.querySelector('.FirstPage');
      // toPNG(ref, 'Editor');
      const prev = await toPNGinPreview({
        ...(await toPNG(ref, 'Editor', true, scale, false)),
        maxW: 1980,
        maxH: 1414,
      });
      const getFile: IGenerateReportData = {
        format,
        preview: prev.replace(base64PngReg, ''),
      };
      try {
        const res = await getSiteDraftPDF(editorId, getFile);

        if (res && res.data) {
          const a = document.createElement('a');
          // const blob = new Blob(res.data, {type: "octet/stream"}),
          a.href = window.URL.createObjectURL(res.data);
          a.target = '_blank';
          a.download = `${editorName}_${moment().format(
            'DD.MM.YYYY_HH_mm',
          )}.${format.toLocaleLowerCase()}`;
          a.click();
        }
      } finally {
        exportReportGeneration(false);
        setScale(s);
        setLoad(false);
      }
    }, [
      loading,
      profile,
      setSnackbarValue,
      editorName,
      editorId,
      scale,
      setScale,
      format,
      exportReportGeneration,
    ]);

    return (
      <div
        className={`${className ? className : ''} ${
          format && cc[format] ? cc[format] : ''
        }`}
        onClick={ExportToFile}
      >
        {!load ? (
          <>
            {format === 'DOC' && (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.6668 30H5.3335V2H20.0002L26.6668 8.66667V30Z"
                  fill="#1976D2"
                />
                <path
                  d="M25.6668 9.33333H19.3335V3L25.6668 9.33333Z"
                  fill="#E3F2FD"
                />
                <path
                  d="M17.7972 20.1518L18.7758 14.8024H20.8152L19.0045 23.3331H16.8718L15.7292 18.4698L14.6098 23.3331H12.4832L10.6665 14.8018H12.7112L13.6898 20.1511L14.8618 14.8018H16.6078L17.7972 20.1518Z"
                  fill="#FAFAFA"
                />
              </svg>
            )}
            {format === 'DOCX' && (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.3332 6.66699H16.6665V25.3337H27.3332C27.7018 25.3337 27.9998 25.0357 27.9998 24.667V7.33366C27.9998 6.96499 27.7018 6.66699 27.3332 6.66699Z"
                  fill="#2196F3"
                />
                <path
                  d="M16.6665 10.001H25.9998V11.3336H16.6665V10.001ZM16.6665 12.667H25.9998V14.0003H16.6665V12.667ZM16.6665 15.3343H25.9998V16.6676H16.6665V15.3343ZM16.6665 18.001H25.9998V19.3336H16.6665V18.001ZM16.6665 20.667H25.9998V22.001H16.6665V20.667Z"
                  fill="white"
                />
                <path d="M18 28L4 25.3333V6.66667L18 4V28Z" fill="#0D47A1" />
                <path
                  d="M14.1112 20.6744H12.2998L11.0985 14.6824C11.0332 14.3644 10.9952 14.0184 10.9825 13.6317H10.9612C10.9325 14.0564 10.8878 14.4064 10.8298 14.6824L9.59651 20.6744H7.71184L5.80518 11.3317H7.58851L8.61251 17.5504C8.65384 17.8197 8.68651 18.1757 8.70784 18.6217H8.73584C8.74851 18.2897 8.80118 17.9211 8.88451 17.5251L10.1978 11.3311H11.9458L13.1358 17.6004C13.1772 17.8324 13.2152 18.1644 13.2492 18.6077H13.2698C13.2832 18.2644 13.3185 17.9177 13.3765 17.5657L14.3785 11.3311H16.0238L14.1112 20.6744Z"
                  fill="white"
                />
              </svg>
            )}
            {format === 'PDF' && (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.6663 30H5.33301V2H19.9997L26.6663 8.66667V30Z"
                  fill="#FF5722"
                />
                <path
                  d="M25.6663 9.33333H19.333V3L25.6663 9.33333Z"
                  fill="#FBE9E7"
                />
                <path
                  d="M10.6669 26C10.4002 26 10.2002 25.9333 10.0002 25.8667C9.2669 25.4667 9.20024 24.8667 9.33357 24.4C9.60024 23.6 11.0669 22.6 13.0002 21.7333C13.8669 20.1333 14.5336 18.4667 14.9336 17.0667C14.2669 15.8 13.9336 14.6 13.9336 13.7333C13.9336 13.2667 14.0669 12.8667 14.2669 12.5333C14.5336 12.2 14.9336 12 15.4669 12C16.0669 12 16.5336 12.3333 16.7336 12.9333C17.0669 13.7333 16.8669 15.2 16.4002 16.8667C17.0669 18 17.8669 19.0667 18.7336 19.8667C20.0002 19.6 21.1336 19.4667 21.8669 19.6C23.1336 19.8 23.3336 20.6667 23.3336 21C23.3336 22.4 21.8669 22.4 21.3336 22.4C20.3336 22.4 19.3336 22 18.4669 21.2667C16.8669 21.6667 15.2669 22.2 14.0002 22.8C13.3336 23.9333 12.6669 24.8667 12.0669 25.4C11.4669 25.8667 11.0002 26 10.6669 26ZM11.4669 24.0667C11.1336 24.2667 10.8669 24.4667 10.7336 24.6667C10.8669 24.6 11.1336 24.4667 11.4669 24.0667ZM20.5336 20.9333C20.8002 21 21.0669 21.0667 21.3336 21.0667C21.7336 21.0667 21.9336 21 22.0002 21C21.9336 20.9333 21.4669 20.8 20.5336 20.9333ZM15.8669 18.5333C15.6002 19.3333 15.2002 20.2 14.8669 21C15.6669 20.7333 16.4669 20.4667 17.2669 20.2667C16.7336 19.7333 16.2669 19.1333 15.8669 18.5333ZM15.4669 13.3333C15.4002 13.3333 15.4002 13.3333 15.4002 13.3333C15.3336 13.4 15.2669 13.8667 15.5336 14.8667C15.6002 14.0667 15.6002 13.4667 15.4669 13.3333Z"
                  fill="white"
                />
              </svg>
            )}
          </>
        ) : (
          <IconsOutline types="loading" width={32} height={32} />
        )}

        <span>{format}</span>
      </div>
    );
  },
);
