import { getProfile, IProfile, onboarding } from '../api/profile.api';
import { useRequest } from './useRequest';
import { useTypeSelector } from './useTypesSelector';
import { useDispatch } from 'react-redux';
import { ProfileReducerActionEnum } from '../store/reducers/profile';
import { useCallback } from 'react';
import { CheckPromoCode, getTarrifsAPI } from '../api/tarrifs.api';
import { SnackbarType, useSnackbar } from './useSnackbar';

export const useProfile = () => {
  const profile = useTypeSelector((s) => s.profile.profile);
  const { request } = useRequest();
  const dispatch = useDispatch();
  const { setSnackbarValue } = useSnackbar();
  const set = useCallback(
    (data: IProfile | null) => {
      dispatch({ type: ProfileReducerActionEnum.setProfile, payload: data });
    },
    [dispatch],
  );

  const fetchProfile = useCallback(async () => {
    if (!profile) {
      const data = await request(getProfile);
      if (data) {
        set(data);
      }
    }
  }, [request, profile, set]);

  const checkProfile = useCallback(async () => {
    if (profile === null) {
      const access = localStorage.getItem('access_token');
      if (access) {
        await fetchProfile();
      }
    } else {
      await fetchProfile();
    }
  }, [profile, fetchProfile]);

  const boardingCheckProfile = useCallback(async () => {
    const { data } = await getProfile();
    localStorage.removeItem('reg_type');
    return data;
  }, []);

  const boardingComplite = useCallback(async () => {
    await request(onboarding);
    localStorage.removeItem('reg_type');
  }, [request]);

  const removeProfile = () => {
    set(null);
  };

  const getTarrifs = useCallback(async () => {
    const { data } = await getTarrifsAPI();
    dispatch({
      type: ProfileReducerActionEnum.setTarrifs,
      payload: data,
    });
  }, [dispatch]);

  const CheckPromo = useCallback(
    async (code: string) => {
      try {
        const { data } = await CheckPromoCode(code);
        dispatch({ type: ProfileReducerActionEnum.setPromo, payload: data });
        await getTarrifs();
        setSnackbarValue('Промокод успешно использован', SnackbarType.Success);
      } catch (e: any) {
        setSnackbarValue(e.error.error_description, SnackbarType.Error);
      }
    },
    [dispatch, setSnackbarValue, getTarrifs],
  );

  const setAutoLong = useCallback(
    (payload: boolean) => {
      dispatch({ type: ProfileReducerActionEnum.setRecurring, payload });
    },
    [dispatch],
  );

  return {
    fetchProfile,
    removeProfile,
    checkProfile,
    boardingCheckProfile,
    boardingComplite,
    CheckPromo,
    getTarrifs,
    setAutoLong,
  };
};
