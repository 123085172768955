import React, { memo, useCallback, useState } from 'react';
import { useDND } from '../../../hooks/useDND';
import { useSettingsEditor } from '../../../hooks/useSettingEditor';
import { SnackbarType, useSnackbar } from '../../../hooks/useSnackbar';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { toPNG } from '../../../utils/toPNG';
import { IconsOutline } from '../../IconOutliner';

export interface IExportToPngProps {
  loading: boolean
  className?: string;
}


export const ExportToPng = memo(({ loading , className }: IExportToPngProps) => {
  const profile = useTypeSelector(s=>s.profile.profile)
  const scale = useTypeSelector((s) => s.settings.dnd.scale);
  const { setScale } = useDND();
  const { exportReportGeneration } = useSettingsEditor();
  const draftName = useTypeSelector((s) => s.editor.name);
  const [load, setLoad] = useState(false);
  const {setSnackbarValue} = useSnackbar()

  const ExportToFile = useCallback(async () => {
    if(loading) return
    if(!profile){
      setSnackbarValue('Вы не авторизованы! Нет права на экспорт файлов', SnackbarType.Error)
      return
    }
    setLoad(true);
    exportReportGeneration(true);
    const s = scale;
    setScale(1);
    const tempref = document.getElementById('editorContent');
    const ref = tempref?.querySelector('.FirstPage');
    try {
      await toPNG(ref, draftName);
    } finally {
      exportReportGeneration(false);
      setScale(s);
      setLoad(false);
      // close()
    }
  }, [
    profile,
    setSnackbarValue,
    loading,
    scale,
    setScale,
    exportReportGeneration,
    draftName,
  ]);

  return (
    <div className={className ? className : ''} onClick={ExportToFile}>
      {!load ? (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.6663 30H5.33301V2H19.9997L26.6663 8.66667V30Z"
          fill="#90CAF9"
        />
        <path d="M25.6663 9.33333H19.333V3L25.6663 9.33333Z" fill="#E1F5FE" />
        <path
          d="M13.9997 15.333L9.33301 21.9997H18.6663L13.9997 15.333Z"
          fill="#1565C0"
        />
        <path
          d="M18.6663 17.5997L15.333 21.9997H21.9997L18.6663 17.5997ZM20.9997 15.333C20.7345 15.333 20.4801 15.4384 20.2926 15.6259C20.105 15.8134 19.9997 16.0678 19.9997 16.333C19.9997 16.5982 20.105 16.8526 20.2926 17.0401C20.4801 17.2277 20.7345 17.333 20.9997 17.333C21.2649 17.333 21.5192 17.2277 21.7068 17.0401C21.8943 16.8526 21.9997 16.5982 21.9997 16.333C21.9997 16.0678 21.8943 15.8134 21.7068 15.6259C21.5192 15.4384 21.2649 15.333 20.9997 15.333Z"
          fill="#1976D2"
        />
      </svg>
      ):(<IconsOutline types='loading' width={32} height={32}/>)}
      <span>PNG</span>
    </div>
  );
});
