import React, { memo, useCallback, useState } from 'react';
import { useDND } from '../../../hooks/useDND';
import { useSettingsEditor } from '../../../hooks/useSettingEditor';
import { SnackbarType, useSnackbar } from '../../../hooks/useSnackbar';
import { useTypeSelector } from '../../../hooks/useTypesSelector';
import { IconsOutline } from '../../IconOutliner';
import { toHTML } from '../../../utils/toHTML';

export interface IExportToPngProps {
  loading: boolean;
  className?: string;
}

export const ExportToHTML = memo(
  ({ loading, className }: IExportToPngProps) => {
    const profile = useTypeSelector((s) => s.profile.profile);
    const scale = useTypeSelector((s) => s.settings.dnd.scale);
    const { setScale } = useDND();
    const { exportReportGeneration } = useSettingsEditor();
    const draftName = useTypeSelector((s) => s.editor.name);
    const [load, setLoad] = useState(false);
    const { setSnackbarValue } = useSnackbar();

    const ExportToFile = useCallback(async () => {
      if (loading) return;
      if (!profile) {
        setSnackbarValue(
          'Вы не авторизованы! Нет права на экспорт файлов',
          SnackbarType.Error,
        );
        return;
      }
      setLoad(true);
      exportReportGeneration(true);
      const s = scale;
      setScale(1);
      try {
        await toHTML(draftName);
      } finally {
        exportReportGeneration(false);
        setScale(s);
        setLoad(false);
        // close()
      }
    }, [
      profile,
      setSnackbarValue,
      loading,
      scale,
      setScale,
      exportReportGeneration,
      draftName,
    ]);

    return (
      <div className={className ? className : ''} onClick={ExportToFile}>
        {!load ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M28 12.6667H4V6C4 4.89533 4.89533 4 6 4H26C27.1047 4 28 4.89533 28 6V12.6667Z"
              fill="#455A64"
            />
            <path
              d="M4 10.667H28V26.0003C28 27.105 27.1047 28.0003 26 28.0003H6C4.89533 28.0003 4 27.105 4 26.0003V10.667Z"
              fill="#BBDEFB"
            />
            <path
              d="M7.33333 6C6.97971 6 6.64057 6.14048 6.39052 6.39052C6.14048 6.64057 6 6.97971 6 7.33333C6 7.68696 6.14048 8.02609 6.39052 8.27614C6.64057 8.52619 6.97971 8.66667 7.33333 8.66667C7.68696 8.66667 8.02609 8.52619 8.27614 8.27614C8.52619 8.02609 8.66667 7.68696 8.66667 7.33333C8.66667 6.97971 8.52619 6.64057 8.27614 6.39052C8.02609 6.14048 7.68696 6 7.33333 6Z"
              fill="#8BC34A"
            />
            <path
              d="M11.3333 6C10.9797 6 10.6406 6.14048 10.3905 6.39052C10.1405 6.64057 10 6.97971 10 7.33333C10 7.68696 10.1405 8.02609 10.3905 8.27614C10.6406 8.52619 10.9797 8.66667 11.3333 8.66667C11.687 8.66667 12.0261 8.52619 12.2761 8.27614C12.5262 8.02609 12.6667 7.68696 12.6667 7.33333C12.6667 6.97971 12.5262 6.64057 12.2761 6.39052C12.0261 6.14048 11.687 6 11.3333 6Z"
              fill="#FFEB3B"
            />
            <path
              d="M25.9998 8C25.9998 8.36867 25.7018 8.66667 25.3332 8.66667H15.3332C14.9645 8.66667 14.6665 8.36867 14.6665 8V6.66667C14.6665 6.298 14.9645 6 15.3332 6H25.3332C25.7018 6 25.9998 6.298 25.9998 6.66667V8Z"
              fill="#FAFAFA"
            />
            <path
              d="M6 13.333H16V25.333H6V13.333ZM18 15.9997H26V17.333H18V15.9997ZM18 18.6663H26V19.9997H18V18.6663ZM18 21.333H26V22.6663H18V21.333ZM18 23.9997H26V25.333H18V23.9997ZM18 13.333H26V14.6663H18V13.333Z"
              fill="#64B5F6"
            />
          </svg>
        ) : (
          <IconsOutline types="loading" width={32} height={32} />
        )}
        <span>HTML</span>
      </div>
    );
  },
);
