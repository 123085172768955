import { makeStyles } from '@material-ui/core';

export const TariffTypeSelectorStyles = makeStyles(() => ({
  tariff_type_selector_wrapper: {
    '& h2': {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      color: '#333333',
      opacity: 0.9,
    },
  },
  tariff_type_selector_wrapper_mobile: {
    '& h2': {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '19px',
      color: '#333333',
      opacity: 0.9,
      textAlign: 'center',
    },
  },
  tariff_type_selector_wrapper__checkbox: {
    width: '100%',
    borderRadius: 8,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    cursor: 'pointer',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      border: '1px solid #084C95',
      width: '100%',
      height: 40,
      color: '#084C95',
      background: 'transparent',
      userSelect: 'none',
      '&.left': {
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
      },
      '&.right': {
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
      },
      '&:hover': {
        border: `1px solid #084C95`,
      },
    },
    '& span.active': {
      fontWeight: 500,
      background: 'rgba(48, 144, 232, 0.10)',
    },
  },
}));
